<template>
    <div class="fair-dice">
        <div class="dice-title">DICE</div>
        <div class="dice-text">
            <p>Our system generates the result for each game by using the SHA-256 hash of 3 separate inputs:</p>
            <p><span>Server Seed:</span> Is a cryptographically secure pseudo-randomly generated string.</p>
            <p><span>Nonce:</span> Is a number that is incremented with every user bet.</p>
            <p><span>Client Seed:</span> Is a string which is determined by the user and can be changed at any time.</p>
        </div>
        <div class="dice-code">
            <pre>
                <code>
                    const crypto = require('crypto');

const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';
const publicSeed = '128b71e19b3c4bd9ec01eba9d6840bdda2a8d0972ac918c9d937c7f861879727';
const nonce = 1; // Increment for each bet

// Generate a provably fair dice roll
const fairDiceRoll = (serverSeed, publicSeed, nonce, rollUnder, betAmount, multiplier) => {
    // Combine seeds and nonce
    const combined = `${serverSeed}-${publicSeed}-${nonce}`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    // Extract roll from hash
    const roll = (parseInt(hash.slice(0, 8), 16) % 1000) + 1; // Roll range: 1-1000

    // Determine result
    const isWin = roll < rollUnder;
    const totalAmount = isWin ? betAmount * multiplier : -betAmount;

    return { roll, isWin, totalAmount };
};

// Example usage
const betAmount = 100;
const rollUnder = 500;
const multiplier = 2;

const result = fairDiceRoll(serverSeed, publicSeed, nonce, rollUnder, betAmount, multiplier);
console.log(result);
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairDice'
    }
</script>

<style scoped>
    .fair-dice {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fair-dice .dice-title {
        font-size: 24px;
        font-weight: 900;
        background: linear-gradient(250deg, #ff3a3a 0%, #ff3a3a 100%), #ff3a3a;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .fair-dice .dice-text {
        margin-top: 12px;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
    }

    .fair-dice .dice-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .fair-dice .dice-text p span {
        color: #ff3a3a;
    }

    .fair-dice .dice-code {
        width: 100%;
        height: 225px;
        margin-top: 25px;
        padding: 16px 4px 16px 16px;
        border-radius: 8px;
        background: #252525;
    }

    .fair-dice .dice-code pre {
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    .fair-dice .dice-code pre::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #ff3a3a;
    }

    .fair-dice .dice-code pre::-webkit-scrollbar {
        width: 3px;
        height: 0;
    }

    .fair-dice .dice-code pre code {
        font-size: 13px;
        font-weight: 600;
        color: rgba(187, 191, 208, 0.5);
    }
</style>