<template>
    <div class="fair-crash">
        <div class="crash-title">CRASH</div>
        <div class="crash-text">
            <p>Our system generates the result for each game by using the SHA-256 hash of 2 separate inputs:</p>
            <p><span>Game ID:</span> The games unique identifier which is generated when the game is created.</p>
            <p><span>Server Seed:</span> Is a list of hashed which is generated from one genesis seed.</p>
            <p>First ever used seed was: b34441cb7cb5f133848cf90a341a9dbff67366de5443c1473958e177cee87f21.</p>
        </div>
        <div class="crash-code">
            <pre>
                <code>
                    const crypto = require('crypto');

const gameId = '6443aa99f4290f71b555f4bd';
const serverSeed = '4c60603de55e3b85fe67450aa124ec34509cb73b0f1fde15';

const fairGetCrashOutcome = () => {
    const combined = `${gameId}-${serverSeed}`;
    const hash = crypto.createHash('sha256').update(combined).digest('hex');

    // Parse the hash
    const h = parseInt(hash.slice(0, 52 / 4), 16);
    
    // Use hash to seed a more controlled random distribution
    const seed = h % 1000;
    
    // Define distribution ranges
    if (seed < 500) {
        const outcome = Math.floor(Math.random() * 50) + 100; // 1x - 2x
        return outcome;
    } else if (seed < 800) {
        const outcome = Math.floor(Math.random() * 100) + 200; // 2x - 3x
        return outcome;
    } else if (seed < 950) {
        const outcome = Math.floor(Math.random() * 200) + 300; // 3x - 5x
        return outcome;
    } else {
        if (h % 200 === 0) {
            const extremeOutcome = Math.floor(Math.random() * 1000) + 1000; // 10x - 20x
            return extremeOutcome;
        }
        
        const outcome = Math.floor(Math.random() * 200) + 500; // 5x - 7x
        return outcome;
    }
};

// Log the outcome
const outcome = fairGetCrashOutcome();
console.log(`Outcome: ${outcome}x`);
                </code>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FairCrash'
    }
</script>

<style scoped>
    .fair-crash {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .fair-crash .crash-title {
        font-size: 24px;
        font-weight: 900;
        background: linear-gradient(250deg, #ff3a3a 0%, #ff3a3a 100%), #ff3a3a;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    .fair-crash .crash-text {
        margin-top: 12px;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
    }

    .fair-crash .crash-text p {
        font-size: 14px;
        font-weight: 600;
        color: #bbbfd0;
    }

    .fair-crash .crash-text p span {
        color: #ff3a3a;
    }

    .fair-crash .crash-code {
        width: 100%;
        height: 225px;
        margin-top: 25px;
        padding: 16px 4px 16px 16px;
        border-radius: 8px;
        background: #252525;
    }

    .fair-crash .crash-code pre {
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    .fair-crash .crash-code pre::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background: #ff3a3a;
    }

    .fair-crash .crash-code pre::-webkit-scrollbar {
        width: 3px;
        height: 0;
    }

    .fair-crash .crash-code pre code {
        font-size: 13px;
        font-weight: 600;
        color: rgba(187, 191, 208, 0.5);
    }
</style>