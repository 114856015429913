const state = {
    diceRoll: 0
}

const getters = {
    diceRoll: state => state.diceRoll
}

const mutations = {
    dice_set_roll(state, diceRoll) {
        state.diceRoll = diceRoll;
    }
}

const actions = {
    diceClearGame({ commit }) {
        commit('dice_set_roll', null);
    },
    diceSocketInit({ commit }, data) {
        //commit('dice_set_roll', data.game);
    },
    diceSendBetSocket({ getters, commit, dispatch }, data) {
        if (getters.socketDice === null || getters.socketSendLoading !== null) { 
            return; 
        }
        commit('socket_set_send_loading', 'DiceBet');

        getters.soundBet.volume = 1;
        getters.soundBet.currentTime = 0;
        getters.soundBet.play();
    
        getters.socketDice.emit('sendBet', data, (res) => {
            if (res.success === true) {
                commit('auth_update_user', res.user);
                commit('dice_set_roll', res.result);
            } else {
                dispatch('notificationShow', res.error);
            }
        
            commit('socket_set_send_loading', null);
        });
    },    
    diceSendRevealSocket({ getters, commit, dispatch }, data) {
        if(getters.socketDice === null || getters.socketSendLoading !== null) { return; }
        commit('socket_set_send_loading', 'DiceReveal');

        getters.socketDice.emit('sendReveal', data, (res) => {
            if(res.success === true) {
                commit('dice_set_game', res.game);

                if(getters.soundMines === 1 && (res.game.state !== 'completed' || res.game.payout > 0)) {
                    getters.soundSuccess.volume = getters.soundVolume;
                    getters.soundSuccess.currentTime = 0;
                    getters.soundSuccess.play();
                } else if(getters.soundMines === 1) {
                    getters.soundExplosion.volume = getters.soundVolume;
                    getters.soundExplosion.play();
                }
            } else {
                dispatch('notificationShow', res.error);
            }

            commit('socket_set_send_loading', null);
        });
    },
}

const dice = {
    state,
    mutations,
    actions,
    getters
}

export default dice;