<template>
    <router-link 
        v-bind:to="'/' + homeGetLink"
        class="home-game-element"
        v-bind:style="{ 'height': homeHeight + 'px' }"
        ref="gameLink"
    >
        <img v-bind:src="require('@/assets/img/games/new/' + game + '.png')" />
    </router-link>
</template>



<script>
    export default {
        name: 'HomePlaceholderElement',
        props: [
            'game'
        ],
        data() {
            return {
                homeHeight: 170
            }
        },
        methods: {
            homeSetHeight() {
                this.homeHeight = 200;
            }
        },
        computed: {
            homeGetLink() {
                let link = this.game;

                if(link === 'cases') { link = 'unbox'; }

                return link;
            }
        },
        mounted() {
            window.addEventListener('resize', this.homeSetHeight);
            this.homeSetHeight();
        },
        destroyed() { 
            window.removeEventListener('resize', this.homeSetHeight); 
        }
    }
</script>


<style scoped>
    /* Container for the images to allow horizontal scrolling */
    .home-game-container {
        display: flex; /* Use flexbox to layout the children */
        overflow-x: auto; /* Enable horizontal scrolling */
        white-space: nowrap; /* Prevent wrapping of items */
        margin: -10px; /* Compensate for the margins of children */
    }

    a.home-game-element {
        height: 150px; /* Base height for the link */
        width: 150px; /* Fixed width for images */
        transition: transform 0.3s ease;
        margin-right: 100px; /* Space between elements */
        display: inline-block; /* Ensures margin works */
    }

    /* Remove margin from the last element */
    a.home-game-element:last-child {
        margin-right: 0; /* Remove margin for the last child */
    }

    a.home-game-element:hover {
        transform: scale(1.03);
    }

    a.home-game-element img {
        height: 130%; /* Maintain the height of the link */
        width: auto; /* Allow the width to adjust while maintaining aspect ratio */
    }

    @media only screen and (max-width: 1050px) {
        a.home-game-element {
            width: 150px; /* Keep fixed width */
        }
    }

    @media only screen and (max-width: 800px) {
        a.home-game-element {
            width: 150px; /* Keep fixed width */
        }
    }

    @media only screen and (max-width: 450px) {
        a.home-game-element {
            height: 200px; /* Adjust height for smaller screens */
        }
    }
</style>
